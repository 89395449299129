import axios from "axios";
import { User } from "../models/User";
import localStorageService from "../service/LocalStorageService";

// Create an Axios instance with a default base URL
const axiosInstance = axios.create({
  baseURL: "https://jatc-api.operationsdistillery.com",
});

axiosInstance.interceptors.request.use(
  (config) => {
    const user = localStorageService.getItem<User>("user");
    if (user?.token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${user.token}`; // Replace with your token
    }
    // inject version
    const apiVersion = localStorageService.getItem<User>("user")?.apiVersion;

    // add api version prefix to every route after user logs in
    if (apiVersion && config.url && !config.url.includes("/login")) {
      // eslint-disable-next-line no-param-reassign
      config.url = `/${apiVersion}${config.url}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    const token = response.headers["refresh-token"];

    if (token) {
      const user = localStorageService.getItem<User>("user");
      localStorageService.setItem<User>(
        "user",
        new User({
          ...user,
          token,
        })
      );
    }
    return response.data;
  },
  (error) => {
    if (error.response?.status === 401) {
      localStorageService.removeItem("user");
      setTimeout(() => {
        window.location.href = "/login";
      }, 100);
    }

    return Promise.reject(error?.response?.data);
  }
);

export default axiosInstance;
